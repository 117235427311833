import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import * as L from '../Utils/Lang';
import * as Icon from "../Components/Icon";

class PartnersSection extends Component{
    constructor(props) {
        super(props);
        this.state = {
            itemPerRow: 4,
        }
    }

    componentDidMount() {
        this._checkScreenSize();
        setTimeout(() => { //It's a hack to solve height problem https://github.com/FormidableLabs/nuka-carousel/issues/265
            window.dispatchEvent(new Event('resize'));
        }, 0);
        window.onresize = () => {
            this._checkScreenSize();
        }
    }

    render() {
        let { itemPerRow } = this.state;
        this._reconstructItem();

        return (
            <Wrapper>
                <div className="title">{L.s('partners')}</div>
                <Carousel
                    framePadding={'50'}
                    renderCenterLeftControls={({ previousSlide }) =>
                        <Icon.KeyboardArrowLeft
                            color={"#0075C1"}
                            style={{ width: 30, height: 30, cursor: "pointer" }}
                            onClick={previousSlide}
                        />
                    }
                    renderCenterRightControls={({ nextSlide }) =>
                        <Icon.KeyboardArrowRight
                            color={"#0075C1"}
                            style={{ width: 30, height: 30, cursor: "pointer" }}
                            onClick={nextSlide}
                        />
                    }
                    renderBottomCenterControls={false}
                >
                    {
                        this._reconstructItem().map((onePage, i) => (
                            <OnePage key={"onePage" + i}>{onePage}</OnePage>
                        ))
                    }
                </Carousel>
            </Wrapper>
        )
    }

    _checkScreenSize = () => {
        let { itemPerRow } = this.state;
        if (window.matchMedia('(min-width: 1024px').matches) {
            itemPerRow !== 4 && this.setState({ itemPerRow: 4 })
        } else if (window.matchMedia('(min-width: 768px)').matches) {
            itemPerRow !== 3 && this.setState({ itemPerRow: 3 })
        } else if (window.matchMedia('(min-width: 600px)').matches) {
            itemPerRow !== 2 && this.setState({ itemPerRow: 2 })
        } else {
            itemPerRow !== 1 && this.setState({ itemPerRow: 1 })
        }
    }

    _reconstructItem = () => {
        let { itemPerRow } = this.state;
        let array = [
            <PartnerItem img="/images/partner-logos/aggregato-global.png" />,
            <PartnerItem img="/images/partner-logos/bankai.png" />,
            <PartnerItem img="/images/partner-logos/china-skyline-telecom.png" />,
            <PartnerItem img="/images/partner-logos/core3-networks.png" />,
            <PartnerItem img="/images/partner-logos/langsen.png" />,
            <PartnerItem img="/images/partner-logos/kslink.png" />,
            <PartnerItem img="/images/partner-logos/quickcom.png" />,
            <PartnerItem img="/images/partner-logos/speedflow.png" />,
            <PartnerItem img="/images/partner-logos/talk2all.png" />,
            <PartnerItem img="/images/partner-logos/yaband.png" />,
            <PartnerItem img="/images/partner-logos/cola-tour.png" />,
            <PartnerItem img="/images/partner-logos/liontravel.png" />,
            <PartnerItem img="/images/partner-logos/sweet-travel-agency.png" />,
        ];
        let result = [];

        array.forEach((e, idx, array) => {
            if (idx % itemPerRow === 0) {
                result.push([e])
            } else {
                let pushIndex = result.length - 1;
                result[pushIndex].push(e);
            }
        })

        return result;
    }
}

const Wrapper = styled.div`
    max-width: 990px;
    margin: 0 auto;
    padding: 100px 0px 150px;

    & > .title {
        margin-bottom: 50px;
        text-align: center;
        font-size: 30px;
        color: #0075C1;
    }

    @media screen and (max-width: 600px) {
        padding: 75px 0px;
    }
`;

const OnePage = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 250px;
`;

const PartnerItem = styled.div`
    display: inline-block;
    width: 160px;
    height: 160px;

    background: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 10px;
`;


export default PartnersSection;