import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import Selectors from '../Selectors';
import ImgTextRow from "../Components/ImgTextRow";
import PartnersSection from "../Components/PartnersSection";

const ItemData = [
  {
    title: "One And Only\nJust For You", subtitle: "為您量身訂做的上網卡",
    subtitlePosition: "top", buttonDisplay: true, buttonText: "了解詳情",
    image: "photo01.png", reverse: false,
  },
  {
    title: "數據漫遊自由配", subtitle: "任意搭配您想要的組合\n為自己量身打造專屬商品\nCreate your own journey",
    subtitlePosition: "bottom", buttonDisplay: true, buttonText: "馬上開始",
    image: "photo02.png", reverse: true,
  },
  {
    title: "TEL-U\nMobile App\n即將上線", subtitle: "電信漫遊資費不在無線上網\n自動偵測高達60個國家，免費撥打電話，\n讓您省下每個月額外的語音帳單。",
    subtitlePosition: "bottom", buttonDisplay: false, buttonText: "",
    image: "photo03.png", reverse: false,
  },
]

class HomePage extends React.Component {
  render() {
    let { home } = this.props;

    return (
      <Wrapper>
        <Container>
          {
            ItemData.map((d, i) => (
              <ImgTextRow
                key={"promotion" + i}
                data={d}
              />
            ))
          }
          <PartnersSection />
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 150px 25px 0px;
  width: 100vw;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1120px;
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      home: Selectors.getHomeData(state),
    }),
    null
  )(HomePage)
);
